import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import AppPage from "../../../../components/AppPage";
import AdminPage from "../../../../components/AdminPage";
import {
  Layout,
  ContextualSaveBar,
  Card,
  Stack,
  Text,
  SkeletonBodyText,
  TextField,
  Button,
  Checkbox,
  Toast
} from '@shopify/polaris';
import { DuplicateMinor } from "@shopify/polaris-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import Handlebars from "handlebars";

import BoutiqAvatar from '../../../../components/Avatar';
import { useHistory } from "react-router-dom";
import { useShopProvider } from "../../../../components/ShopProvider";
import useShopAgentConfig from "../../../../hooks/useShopAgentConfig";
import useShopUsers from "../../../../hooks/useShopUsers";
import { compareObjects } from "../../../../utils/objects";
import { isValidUrl } from "../../../../utils/paths";
import { debounce } from "../../../../utils/debounce";


const DEFAULT_CONFIG = {
  en: {
    introductionText: "Hi! 👋. I'm {{hostName}}.\nIf you have questions about {{brandName}}'s products I'm here to help.\nLet me know how I can assist you today."
  }
}

const AgentSettingsPage = () => {
  const history = useHistory();

  const { shopOrigin, hosts, shopData } = useShopProvider();
  const { updateHostAvatar } = useShopUsers(shopOrigin);
  const locale = 'en';
  const [newConfig, setNewConfig] = useState(null);
  const [introductionText, setIntroductionText] = useState(DEFAULT_CONFIG[locale].introductionText);
  const [introductionTextPreview, setIntroductionTextPreview] = useState(null);
  const [introductionTextError, setIntroductionTextError] = useState(null);
  const [dirtyBit, setDirtyBit] = useState(false);
  const [errorBit, setErrorBit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [followUpLinkError, setFollowUpLinkError] = useState(false);
  const { boutiqAIConfig, boutiqAIConfigLoading, setBoutiqAIConfig, } = useShopAgentConfig(shopOrigin);
  const [showToast, setShowToast] = useState(false);

  const [customAvatarImageSrc, setCustomAvatarImageSrc] = useState(null);
  const [customAvatarFile, setCustomAvatarFile] = useState(null);

  const avatarFileInput = useRef(null);

  const host = useMemo(() => hosts?.find(h => h.id === boutiqAIConfig?.botHostId), [boutiqAIConfig, hosts]);
  const featureEnabled = newConfig?.enabled && shopData?.productPolicy?.supportsBoutiqAIAgent;
  const shopSmartAgentLink = useMemo(() => `${shopData.primaryDomain.url}/apps/video/vs/AIAgentLink/start`, [shopData]);

  useEffect(() => {
    setNewConfig(boutiqAIConfig);
    setIntroductionText(boutiqAIConfig?.introductionText?.[locale] ?? DEFAULT_CONFIG[locale].introductionText);
    console.log('boutiqAIConfig', boutiqAIConfig);
  }, [boutiqAIConfig]);

  useEffect(() => {
    //load defaults to some feilds
    if (newConfig?.enabled) {
      const defaults = {};
      if (shopData?.name && !newConfig?.brandName) {
        defaults.brandName = shopData.name;
      }

      if (shopData?.name && !newConfig?.title) {
        defaults.title = `${shopData.name} Team`;
      }

      if (host?.displayName && !newConfig?.botName) {
        defaults.botName = host.displayName;
      }

      if (!newConfig?.smartAgentServiceName) {
        defaults.smartAgentServiceName = 'Smart Agent';
      }
      setNewConfig({ ...newConfig, ...defaults });
    }
  }, [newConfig?.enabled])

  useEffect(() => {
    if (customAvatarFile) {
      let reader = new FileReader();
      reader.readAsDataURL(customAvatarFile);
      reader.onloadend = (e) => {
        setCustomAvatarImageSrc(reader.result);
      }
    } else {
      setCustomAvatarImageSrc(null);
    }

  }, [customAvatarFile])

  useEffect(() => {
    setErrorBit(false);
    let dirtyBit = false;
    if (!compareObjects(newConfig ?? {}, boutiqAIConfig ?? {})) {
      dirtyBit = dirtyBit || true;
      if (!newConfig?.botName || !newConfig?.brandName || !newConfig?.title || !newConfig?.smartAgentServiceName) {
        setErrorBit(true);
      }
      if (newConfig?.followUpLinkUrl && !isValidUrl(newConfig.followUpLinkUrl)) {
        setErrorBit(true);
      }
    }

    if (customAvatarFile) {
      dirtyBit = dirtyBit || true;
    }

    if (introductionTextError){
      setErrorBit(true);
    }

    setDirtyBit(dirtyBit);
  }, [newConfig, customAvatarFile, introductionTextError]);

  useEffect(() => {
    compileTemplate(introductionText, {
      serviceName: newConfig?.smartAgentServiceName,
      brandName: newConfig?.brandName,
      hostName: newConfig?.botName
    });
  }, [introductionText, newConfig]);
  
  // Debounce function to delay compilation until user stops typing
  const compileTemplate = useCallback(
    debounce((template, context) => {
      try {
        const compiledTemplate = Handlebars.compile(template);
        const preview = compiledTemplate(context);
        setIntroductionTextPreview(preview);
        setIntroductionTextError(null);
      } catch (error) {
        setIntroductionTextPreview(null);
        setIntroductionTextError('template is invalid');
      }
    }, 500),
    []
  );


  const updateIntroductionText = (locale, value) => {
    if (value) {
      setIntroductionText(value);
      setNewConfig({ ...newConfig, introductionText: { [locale]: value } });      
    } else {
      setIntroductionText(DEFAULT_CONFIG[locale].introductionText);
      const updatedIntroText = { ...(newConfig?.introductionText || {}) };
      delete updatedIntroText[locale];
      setNewConfig({ ...newConfig, introductionText: updatedIntroText });
    }
  }

  const save = async () => {
    setSaving(true);

    try {
      if (customAvatarFile && host) {
        await updateHostAvatar(host.id, customAvatarFile);
      }
      await setBoutiqAIConfig(newConfig);
    } catch (error) {
      console.error('Failed saving boutiqAIConfig data', error);
    } finally {
      setSaving(false);
      setDirtyBit(false);
    }
  }

  const onDiscard = () => {
    setNewConfig(boutiqAIConfig);
    setIntroductionText(boutiqAIConfig?.introductionText?.[locale] ?? DEFAULT_CONFIG[locale].introductionText);
    setCustomAvatarFile(null);
    setDirtyBit(false);
    setFollowUpLinkError(false);
  }

  const handleAvatarChangeClick = () => {
    avatarFileInput.current.click();
  }

  const handleNewAvatar = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCustomAvatarFile(file);
    }
  }

  const checkFollowUpUrl = () => {
    if (newConfig?.followUpLinkUrl && !isValidUrl(newConfig.followUpLinkUrl)) {
      setFollowUpLinkError(true);
    } else {
      setFollowUpLinkError(false);
    }
  }

  const toggleLinkCopyToastActive = useCallback(() => setShowToast((showToast) => !showToast), []);

  const toastMarkup = showToast ? (
    <Toast content="Smart Agent link copied to clipboard" onDismiss={toggleLinkCopyToastActive} />
  ) : null;

  return (
    <AppPage
      title={<>Boutiq <sup>AI</sup> Smart Agent settings</>}
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
    >
      <Layout>
        <Layout.AnnotatedSection>
          <Card sectioned>
            {boutiqAIConfigLoading
              ? <SkeletonBodyText lines={4} />
              : <Stack vertical>
                <Checkbox
                  checked={newConfig?.enabled}
                  label={<>Enable the Boutiq <sup>AI</sup> Smart Agent</>}
                  onChange={(value) => setNewConfig({ ...newConfig, enabled: value })}
                />
                {featureEnabled && <Stack spacing='extraTight' alignment='center'>
                  <TextField
                    label='Shop Smart Agent link'
                    value={shopSmartAgentLink}
                    disabled
                    helpText="HTML link that can be shared with clients to directly activate the Smart Agent app"
                  />
                  <CopyToClipboard text={shopSmartAgentLink} onCopy={toggleLinkCopyToastActive}>
                    <Button icon={DuplicateMinor} />
                  </CopyToClipboard>
                </Stack>}
              </Stack>
            }
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Smart Agent settings'
          description='Configure the Smart Agent host settings'
        >
          <Card sectioned >
            {boutiqAIConfigLoading
              ? (
                <Stack vertical>
                  <SkeletonBodyText lines={4} />
                </Stack>
              )
              : <Card.Section>
                <Stack vertical >
                  <TextField
                    label="Smart Agent service name"
                    value={newConfig?.smartAgentServiceName}
                    onChange={(value) => setNewConfig({ ...newConfig, smartAgentServiceName: value })}
                    helpText="The name of the Smart Agent service as presented to clients"
                    disabled={!featureEnabled}
                    error={!newConfig?.smartAgentServiceName && featureEnabled ? 'Smart Agent service name is required' : null}
                  />
                  <Stack vertical spacing='extraTight'>
                    <Text fontWeight="semibold">Host Agent</Text>
                    <Stack alignment="center">

                      {host && <BoutiqAvatar
                        source={customAvatarImageSrc ?? host?.avatarUrl ?? null}
                        name={host?.firstName}
                        initials={`${host?.firstName?.[0]}${host?.lastName?.[0]}`}
                      />}

                      {host && <Stack vertical>
                        <Text fontWeight="semibold">{host?.firstName} {host?.lastName}</Text>
                      </Stack>}
                    </Stack>
                    <Button plain onClick={handleAvatarChangeClick} disabled={!featureEnabled}>Change avatar</Button>
                    <input ref={avatarFileInput} type="file" accept="image/jpeg,image/png" onChange={handleNewAvatar} style={{ display: 'none' }} />
                  </Stack>
                  <TextField
                    label="Smart Agent name"
                    value={newConfig?.botName}
                    onChange={(value) => setNewConfig({ ...newConfig, botName: value })}
                    error={!newConfig?.botName && featureEnabled ? 'Agent name is required' : null}
                    helpText="The name of the Smart Agent - used in interactions with clients"
                    disabled={!featureEnabled}
                  />
                  <TextField
                    label="Brand name"
                    value={newConfig?.brandName}
                    onChange={(value) => setNewConfig({ ...newConfig, brandName: value })}
                    error={!newConfig?.brandName && featureEnabled ? 'Brand name is required' : null}
                    helpText="The name of the brand the Smart Agent represents"
                    disabled={!featureEnabled}
                  />
                  <Checkbox
                    label="Show Smart Agent button in Boutiq client app"
                    checked={newConfig?.showButtonInClientApp}
                    onChange={(value) => setNewConfig({ ...newConfig, showButtonInClientApp: value })}
                    helpText="If enabled, a Smart Agent button will be shown in the Boutiq client app"
                    disabled={!featureEnabled}
                  />
                </Stack>
              </Card.Section>
            }
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Smart Agent app settings'
          description='Customize the Smart Agent app presented to your clients'
        >
          <Card sectioned>
            {boutiqAIConfigLoading
              ? (
                <Stack vertical>
                  <SkeletonBodyText lines={4} />
                </Stack>
              )
              : <>
                <Card.Section>
                  <Stack vertical>
                    <TextField
                      label="Title"
                      value={newConfig?.title}
                      onChange={(value) => setNewConfig({ ...newConfig, title: value })}
                      helpText="The title of the Smart Agent app"
                      error={!newConfig?.title && featureEnabled ? 'Title is required' : null}
                      disabled={!featureEnabled}
                    />
                    <Stack vertical spacing='tight'>
                      <TextField
                        label="Introduction text"
                        multiline={4}
                        value={introductionText}
                        onChange={(value) => updateIntroductionText(locale, value)}
                        helpText="The Smart Agent service introduction text presented to clients"
                        disabled={!featureEnabled}
                      />
                      {introductionTextPreview && <Text color="subdued">{introductionTextPreview}</Text>}
                      {introductionTextError && <Text color="critical">{introductionTextError}</Text>}
                    </Stack>
                  </Stack>
                </Card.Section>
                <Card.Section>
                  <TextField
                    label="Boutiq human host redirection"
                    value={newConfig?.followUpLinkUrl}
                    onChange={(value) => setNewConfig({ ...newConfig, followUpLinkUrl: value })}
                    onBlur={checkFollowUpUrl}
                    helpText="The Boutiq calling/scheduling page URL to present to clients in case they need help from a human host"
                    placeholder={`${shopData.primaryDomain.url}/pages/boutiq-call`}
                    disabled={!featureEnabled}
                    error={followUpLinkError && featureEnabled ? 'Invalid URL' : null}
                  />
                </Card.Section>
              </>
            }
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      {dirtyBit && <ContextualSaveBar
        message="Save agent settings?"
        saveAction={{
          onAction: save,
          loading: saving,
          disabled: errorBit,
        }}
        discardAction={{
          onAction: onDiscard,
          disabled: saving,
        }}
      />}
      {toastMarkup}
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <AgentSettingsPage />
    </AdminPage>
  )
}
