import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import Moment from 'moment-timezone';
import {
  Stack,
  Layout,
  Card,
  Spinner,
  Button,
  Heading,
  ButtonGroup,
  Avatar,
  Popover,
  ActionList,
  TextStyle
} from '@shopify/polaris';
import {
  ClockMinor,
  AdjustMinor,
  ProfileMinor,
  CalendarMinor,
} from '@shopify/polaris-icons';

import AdminPage from '../../components/AdminPage';
import AppPage from '../../components/AppPage';
import AppWarnings from '../../components/AppWarnings';
import { useShopProvider } from '../../components/ShopProvider';
import { isInBusinessHours } from '../../components/BusinessHours';
import useShopCalendar from '../../hooks/useShopCalendar';
import useHostSchedulingConfig from '../../hooks/useHostSchedulingConfig';
import { DEFAULT_LOOKAHEAD_DAYS, ScheduleStatus } from '../../utils/consts';
import CaazamEventModal from './event';
import CaazamBlockOffModal from './newBlockOff';
import CaazamSlotModal from './slot';
import SelectHost, { SELECT_ALL_HOSTS_OPTION } from '../../components/SelectHost';
import useShopUsers from '../../hooks/useShopUsers';
import { DEFAULT_SLOT_DURATION } from '../../utils/consts'
import useShopScheduleBlocks from '../../hooks/useShopScheduleBlocks';
import { CalendarDatePicker } from './components/DatePicker/DatePicker';
import { getContrast } from '../../utils/colorContrast';
import { CaazamBlockModal } from './modalBlock';
import { useAdminShops } from '../../hooks/useAdminShops';
import { useAppAuthState } from '../../authState';
import { UserRole } from '../../utils/permissions';
import { CaazamEventEditorModal } from './components/editEvent';
import CreateNewAppointmentProvider from '../../components/CreateNewAppointmentProvider';
import { AppointmentSearcher } from './components/AppointmentSearcher';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import useHostAvailability from "../../hooks/useHostAvailability";
import {HOST_AVAILABILITY} from "../../constants/const";
import {CallRoutePolicy} from "../../components/CallRoutingPolicyConfig";

const localizer = momentLocalizer(Moment);

const LEGEND_ITEMS = (view) =>
  [
    {
      label: 'Completed call',
      type: 'completed'
    },
    {
      label: 'Assigned call',
      type: null
    },
    {
      label: 'Pending call',
      type: 'pending'
    },
    {
      label: 'Host Availability (slots offered to clients)',
      type: 'availability'
    },
    {
      label: 'Host blocked',
      type: 'block',
      isHidden: view === Views.MONTH
    }
  ]

const PENDING_RESOURCE_VIEW = {
  resourceId: "pending_call",
  resourceTitle: "pending"
}

const DEFAULT_CALENDAR_TIME = {
  start: new Date().setHours(9, 0, 0),
  end: new Date().setHours(18, 0, 0),
  minDuration: 6
}

export const CalendarEventType = {
  Slot: 'Slot',
  Event: 'Event',
  Block: 'Block',
  HostEvent: 'HostEvent',
  CallAvailability: 'CallAvailability'
}

const viewTypeQuery = new URLSearchParams(window.location.search).get('viewType');
const dateQuery = new URLSearchParams(window.location.search).get('date');

function ShopCalendar() {
  const history = useHistory();
  const { shopOrigin, businessHoursConfig, businessHoursLoading, schedulingConfig, schedulingConfigLoading, callPolicyConfig } = useShopProvider();
  const { hosts } = useShopUsers(shopOrigin);
  const { adminShops, adminShopsLoading } = useAdminShops();
  const { adminRole } = useAppAuthState();

  const [resourceView, setResourceView] = useState(viewTypeQuery === Views.DAY);
  const [withSlots, setWithSlots] = useState(viewTypeQuery !== Views.MONTH);
  const [pendingCallResourceView, setPendingCallResourceView] = useState(false);
  const [hostResourceMap, setHostResourceMap] = useState(undefined)
  const [currentViewType, setCurrentViewType] = useState(viewTypeQuery || Views.WEEK)

  const [eventsForCalendar, setEventsForCalendar] = useState([]);
  const [date, setDate] = useState(dateQuery ? new Date(dateQuery) : new Date())
  const [host, setHost] = useState(SELECT_ALL_HOSTS_OPTION.value);
  const [currentRange, setCurrentRange] = useState({
    start: Moment(date).startOf('week').toDate(),
    end: Moment(date).endOf('week').toDate(),
  });

  const [backgroundEvents, setBackgroundEvents] = useState([])
  const scrollToTime = useMemo(() => (
    new Date(2000, 1, 1, 8)
  ), [])

  const [datePickerModalOpen, setDatePickerModalOpen] = useState(false);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [newBlockOffModalOpen, setNewBlockOffModalOpen] = useState(false);
  const [slotModalOpen, setSlotModalOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedslot, setSelectedSlot] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);

  const isAllHosts = host === SELECT_ALL_HOSTS_OPTION.value;
  const isCustomCallPolicy = useMemo(() => (
    callPolicyConfig && callPolicyConfig.callRoutingPolicy.type === CallRoutePolicy.custom
  ), [callPolicyConfig])
  const { shopEvents, shopEventsLoading, slots, slotsLoading } = useShopCalendar(shopOrigin, currentRange.start, currentRange.end, withSlots);
  const { scheduleBlocks, scheduleBlocksLoading } = useShopScheduleBlocks(shopOrigin, currentRange.start, currentRange.end);
  const {availabilityWindows: hostCallsWindows} = useHostAvailability(
    shopOrigin, currentRange, HOST_AVAILABILITY.CALLS, isCustomCallPolicy
  )
  const { getHostConfig } = useHostSchedulingConfig(shopOrigin);
  const [minTime, setMinTime] = useState(DEFAULT_CALENDAR_TIME.start);
  const [maxTime, setMaxTime] = useState(DEFAULT_CALENDAR_TIME.end);

  const [newEventModalOpen, setNewEventModalOpen] = useState(false)
  const [createEventsListActive, setCreateEvantsListActive] = useState(false);
  const [ lookAheadDays, setLookAheadDays] = useState(DEFAULT_LOOKAHEAD_DAYS.toString());

  const [preselectedScheduleData, setPreselectedScheduleData] = useState(null)

  useEffect(() => {
    if (!schedulingConfigLoading && schedulingConfig) {
      setLookAheadDays((schedulingConfig.lookAheadDays && schedulingConfig.lookAheadDays.toString()) || DEFAULT_LOOKAHEAD_DAYS.toString())
    }
}, [schedulingConfig, schedulingConfigLoading])

  const togglePopoverActive =
    () => setCreateEvantsListActive((popoverActive) => !popoverActive)

  const mapEvent = (shopEventDoc) => {
    const shopEvent = shopEventDoc.data();
    let eventRes;
    switch (shopEvent.type) {
      case 'host_event':
        eventRes = {
          id: shopEventDoc.id,
          title: shopEvent.title,
          description: shopEvent.description,
          start: shopEvent.startTimestamp.toDate(),
          end: shopEvent.endTimestamp.toDate(),
          resource: shopEvent.hostId,
          resourceId: shopEvent.hostId,
          eventType: shopEvent.eventType,
          status: shopEvent.status,
          type: CalendarEventType.HostEvent,
        }
        break;
      default:
        eventRes = {
          id: shopEventDoc.id,
          title: shopEvent.customer.name || shopEvent.customer.email,
          start: shopEvent.startTimestamp.toDate(),
          end: shopEvent.endTimestamp.toDate(),
          resource: shopEvent.hostId,
          resourceId: shopEvent.hostId || (shopEvent.status === ScheduleStatus.pending && 'pending_call'),
          eventType: shopEvent.eventType,
          status: shopEvent.status,
          type: CalendarEventType.Event,
        }
        break;
    }
    return eventRes
  }

  const filterEvent = (shopEventDoc) => {
    const shopEvent = shopEventDoc.data();
    switch (shopEvent.type) {
      case 'host_event':
        // don't show deleted events of this type
        return [ScheduleStatus.assigned, ScheduleStatus.pending, ScheduleStatus.completed].includes(shopEvent.status);
      default:
        return true;
    }
  }

  const mapSlot = (slot) => {
    return {
      id: slot.start,
      start: new Date(slot.start),
      end: new Date(slot.end),
      hosts: [slot.hostId],
      status: slot.isAvailable,
      resourceId: slot.hostId,
      type: CalendarEventType.Slot,
    }
  }

  const mapBlock = (block) => {
    const blockData = block.data();
    return {
      id: block.id,
      shopId: blockData.shopId,
      title: adminShops?.[blockData.shopId]?.name ?? 'Blocked off',
      start: blockData.startTimestamp.toDate(),
      end: blockData.endTimestamp.toDate(),
      resourceId: blockData.hostId,
      type: CalendarEventType.Block,
    }
  }

  const mapCallsAvailability = (event) => {
    return {
      id: event.eventId,
      shopId: shopOrigin,
      title: 'Instant calls availability',
      start: event.windowStart,
      end: event.windowEnd,
      resourceId: event.hostId,
      type: CalendarEventType.CallAvailability,
    }
  }

  useEffect(() => {
    let events = [];
    let hostSlots = [];

    let minNotice = schedulingConfig?.minNotice ?? schedulingConfig?.slotDuration ?? DEFAULT_SLOT_DURATION;

    console.log('useEffect', currentViewType, isAllHosts, host)

    const hostSlotReducer = (agg, slot) => {
      if (Moment(slot.start).diff(Moment().add(minNotice, 'minutes')) < 0)
        return agg;
      if (!slot.isAvailable)
        return agg;
      if (!isAllHosts && slot.hostId !== host)
        return agg;
      let prev = agg.length;
      if (prev > 0 && agg[prev - 1].start.toISOString() === slot.start) {
        agg[prev - 1].hosts.push(slot.hostId)
      } else {
        agg.push(mapSlot(slot));
      }
      return agg;
    }

    const hostSlotFilter = (host, slot) => {
      const isHostSlot = isAllHosts || host === slot.hostId;
      const isSlotAvailable = Moment(slot.start).diff(Moment().add(minNotice, 'minutes')) >= 0 && slot.isAvailable;
      return isHostSlot & isSlotAvailable;
    }

    let min, max;
    const minMaxReducer = ({ start, end }) => {
      // work around for drill down views where the range mismatches the view type
      if (currentViewType !== Views.DAY || Moment(start).isSame(Moment(date), 'date')) {
        const startEventTime = Moment(start).format('HH:mm');
        const endEventTime = Moment(end).format('HH:mm');
        if (!min || min > startEventTime) min = startEventTime
        if (!max || max < endEventTime) max = endEventTime
      }
    }

    if (!shopEventsLoading) {
      const mappedEvents = shopEvents.docs.filter(filterEvent).map(mapEvent);
      events = isAllHosts ? mappedEvents : mappedEvents.filter(event => event.resource === host);
    }

    if (!scheduleBlocksLoading && scheduleBlocks && !adminShopsLoading && currentViewType !== Views.MONTH) {
      let blocks = isAllHosts ? scheduleBlocks.docs.map(mapBlock) : scheduleBlocks.docs.map(mapBlock).filter(event => event.resourceId === host);
      events = [...events, ...blocks]
    }
    if (hostCallsWindows.length && currentViewType === Views.DAY) {
      let windows = isAllHosts ? hostCallsWindows.map(mapCallsAvailability) : hostCallsWindows.map(mapCallsAvailability).filter(event => event.resourceId === host)
      events = [...events, ...windows]
    }
    events.forEach(minMaxReducer);

    if (withSlots) {
      if (!resourceView) {
        hostSlots = slots.reduce(hostSlotReducer, []);
        setPendingCallResourceView(false);
      } else {
        hostSlots = slots.filter((slot) => hostSlotFilter(host, slot)).map(mapSlot);
        setPendingCallResourceView(events.some(event => event.resourceId === 'pending_call'));
      }
      setBackgroundEvents(hostSlots);
      if (hostSlots.length > 0) {
        hostSlots.forEach(minMaxReducer);
      }
    }

    if (!min) {
      min = Moment(DEFAULT_CALENDAR_TIME.start).format('HH:mm');
      max = Moment(DEFAULT_CALENDAR_TIME.end).format('HH:mm');
    }
    calendarTimeHandler(min, max);
    setEventsForCalendar(events)
  }, [shopEvents,
    shopEventsLoading,
    slots,
    scheduleBlocks,
    scheduleBlocksLoading,
    host,
    isAllHosts,
    withSlots,
    resourceView,
    currentViewType,
    adminShops, adminShopsLoading, //mapBlock depends on this data
  ]);

  useEffect(() => {
    if (resourceView) {
      const selectedHosts = host === SELECT_ALL_HOSTS_OPTION.value ? hosts.filter(hostItem => !hostItem.automated) : hosts.filter(hostItem => hostItem.id === host);
      const hostResourseMapList = selectedHosts.map(host => ({ resourceTitle: `${host.firstName} ${host.lastName}`, resourceId: host.id }))
      if (pendingCallResourceView) {
        setHostResourceMap([...hostResourseMapList, ...[PENDING_RESOURCE_VIEW]])
      } else {
        setHostResourceMap(hostResourseMapList)
      }
    } else {
      setHostResourceMap(undefined);
    }
  }, [host, hosts, resourceView, pendingCallResourceView])

  const calendarTimeHandler = (start, end) => {
    let startRes = Moment(start, 'HH:mm');
    let endRes = Moment(end, 'HH:mm');
    const duration = Moment.duration(endRes.diff(startRes, 'HH:mm'))
    const hours = duration.asHours();

    if (hours < DEFAULT_CALENDAR_TIME.minDuration) {
      let x = DEFAULT_CALENDAR_TIME.minDuration - hours;
      if (x > .5 && x <= 1.5) {
        startRes = Moment.max(Moment('00:00', 'HH:mm'), startRes.subtract(.5, 'h'))
      } else {
        startRes = Moment.max(Moment('00:00', 'HH:mm'), startRes.subtract(Math.ceil(x / 2), 'h'))
        endRes = Moment.min(Moment('23:59', 'HH:mm'), endRes.add(Math.ceil(x / 2), 'h'))
      }
    }
    setMinTime(Moment(startRes).startOf('hour').toDate());
    setMaxTime(endRes.toDate())
  }

  const isHostFreeForEvent = (hostId, forEvent) => {
    let selectedStart = Moment(forEvent.startTimestamp.toDate());
    let selectedEnd = Moment(forEvent.endTimestamp.toDate());

    // checks if this host has an avialable slot at this same time
    let isHostSlotAvailable = !!slots.find(slot => slot.hostId === hostId && Moment(slot.start).isSame(selectedStart) && slot.isAvailable);

    // checks if this host is busy with an overlapping appointment
    let isHostEventBusy = !!shopEvents.docs.find(eventDoc => {
      let eventHost = eventDoc.data().hostId;
      let eventStart = eventDoc.data().startTimestamp.toDate();
      let eventEnd = eventDoc.data().endTimestamp.toDate();

      return eventHost === hostId && (eventEnd > selectedStart && eventStart < selectedEnd);
    });

    return isHostSlotAvailable && !isHostEventBusy;
  }

  const isHostFreeForBlockOff = (hostId, forEvent) => {
    let selectedStart = Moment(forEvent.startTimestamp)
    let selectedEnd = Moment(forEvent.endTimestamp);

    // checks if this host is busy with an overlapping appointment
    let isHostEventBusy = !!shopEvents.docs.find(eventDoc => {
      let eventHost = eventDoc.data().hostId;
      let eventStart = eventDoc.data().startTimestamp.toDate();
      let eventEnd = eventDoc.data().endTimestamp.toDate();

      return eventHost === hostId && (eventEnd > selectedStart && eventStart < selectedEnd);
    });

    return !isHostEventBusy;
  }

  const Event = useCallback(({ event }) => {
    if (event.type === CalendarEventType.Event) {
      let styles = {}
      let color = {}
      if (event.eventType?.color) {
        styles = {
          '--color': event.eventType?.color
        }
        if (event.status === ScheduleStatus.assigned
          || event.status === ScheduleStatus.completed
          || event.status === ScheduleStatus.deleted
        ) {
          let isContrast;
          if (event.status === ScheduleStatus.completed) {
            isContrast = getContrast(event.eventType?.color, .4, '#ececec');
          } else {
            isContrast = getContrast(event.eventType?.color);
          }
          const isContrastColor = isContrast ? 'rgb(32, 34, 35)' : 'white'
          color = { color: event.status === ScheduleStatus.deleted ? 'grey' : isContrastColor }
        }
      }
      const currentHost = hosts.find(item => item.id === event.resourceId);
      return (
        <div className='event-wrapper' style={styles}>
          <div className='event-wrapper-content'>
            {currentHost && <Avatar
              size="extraSmall"
              source={currentHost.avatarUrl}
              initials={`${currentHost.firstName[0]}${currentHost.lastName[0]}`} />}
            <strong style={color}>{event.title}</strong>
          </div>
        </div>
      )
    } else if (event.type === CalendarEventType.Block
      || event.type === CalendarEventType.HostEvent
      || event.type === CalendarEventType.CallAvailability
    ) {
      const currentHost = hosts.find(item => item.id === event.resourceId);

      return (
        <div className='event-wrapper'>
          <div className='event-wrapper-content'>
            {currentHost && <Avatar
              size="extraSmall"
              source={currentHost.avatarUrl}
              initials={`${currentHost.firstName[0]}${currentHost.lastName[0]}`} />}
            <strong>{event.title}</strong>
          </div>
        </div>
      );
    }
    else {
      return (<span></span>);
    }
  }, [hosts])

  const eventPropGetter = useCallback(data => {
    if (data.type === CalendarEventType.Event) {
      switch (data.status) {
        case ScheduleStatus.pending:
          return { className: 'rbc-event-pending' };
        case ScheduleStatus.completed:
          return { className: 'rbc-event-completed' };
        case ScheduleStatus.deleted:
          return { className: 'rbc-event-deleted' };
        case ScheduleStatus.assigned:
          return { className: 'rbc-event-assigned' };
        default:
          return;
      }
    } else if (data.type === CalendarEventType.Slot) {
      return { className: 'rbc-event-slot' };
    } else if (data.type === CalendarEventType.Block
      || data.type === CalendarEventType.HostEvent
      || data.type === CalendarEventType.CallAvailability
    ) {
      return { className: 'rbc-event-block' };
    }
  }, [eventsForCalendar])

  const slotPropGetter = useCallback((slot) => {
    const open = isInBusinessHours(businessHoursConfig, slot);
    if (!open) {
      return { className: 'slot-shop-closed' }
    } else {
      return null;
    }
  }, [businessHoursConfig]);

  const onSelectEvent = useCallback((data) => {
    if (data.type === CalendarEventType.Event) {
      const shopEventData = shopEvents.docs.find(doc => data.id === doc.id);
      if (shopEventData) {
        setSelectedEvent({ id: shopEventData.id, ...shopEventData.data() });
        setSelectedBlock(null);
        setSelectedSlot(null);
        setEventModalOpen(true);
      }
    } else if (data.type === CalendarEventType.Slot) {
      setSelectedSlot(data);
      setSelectedBlock(null);
      setSelectedEvent(null);
      setSlotModalOpen(true);
    } else if (data.type === CalendarEventType.Block) {
      const currentHost = hosts.find(item => item.id === data.resourceId);
      setSelectedEvent(null);
      setSelectedBlock({ ...data, host: currentHost });
      setSelectedSlot(null);
      setBlockModalOpen(true);
    } else if (data.type === CalendarEventType.HostEvent) {
      setSelectedEvent(null);
      setSelectedBlock(data);
      setSelectedSlot(null);
      setNewBlockOffModalOpen(true)
    } else if (data.type === CalendarEventType.CallAvailability) {
      const currentHost = hosts.find(item => item.id === data.resourceId);
      setSelectedEvent(null);
      setSelectedBlock({
        ...data,
        modalTitle: (
          <p>Instant call availability for <TextStyle variation='strong'>{`${currentHost.firstName} ${currentHost.lastName}`}</TextStyle></p>
        ),
        host: currentHost
      });
      setSelectedSlot(null);
      setBlockModalOpen(true);
    }
  }, [setSelectedSlot, setSelectedEvent, setSlotModalOpen, shopEvents, hosts])

  const onView = (view) => {
    setCurrentViewType(view);
    setResourceView(view === Views.DAY)
    setWithSlots([Views.WEEK, Views.DAY].includes(view));
  }

  const onRangeChange = range => {
    let newStart, newEnd;
    if (Array.isArray(range)) {
      const datesCount = range.length;
      if (datesCount === 1) {
        newStart = Moment(range[0]);
        newEnd = Moment(range[0]).endOf('day');
      }
      else {
        newStart = Moment(range[0]);
        newEnd = Moment(range[datesCount - 1]).endOf('day');
      }
    } else {
      newStart = Moment(range.start);
      newEnd = Moment(range.end);
    }

    setCurrentRange({ start: newStart.toDate(), end: newEnd.toDate() });
  }

  const onNavigate = (newDate, view, action) => {
    setDate(newDate)
  }

  const rescheduleAppointment = () => {
    setEventModalOpen(false);

    setPreselectedScheduleData({
      id: selectedEvent.id,
      hostId: selectedEvent.hostId,
      name: selectedEvent.customer?.name,
      email: selectedEvent.customer?.email,
      timezone: selectedEvent.customer?.timezone,
      eventTypeId: selectedEvent.eventType?.eventTypeId,
      slot: {
        start: selectedEvent.startTimestamp.toDate(),
        end: selectedEvent.endTimestamp.toDate()
      }
    })

    setNewEventModalOpen(true);
  }

  const editHostAction = (hostId, label) => adminRole === UserRole.admin ? <Button plain onClick={() => history.push(`/calendar/hosts/${hostId}`)}>{label}</Button> : null;
  const schedule = (hostId, slot, label) => adminRole === UserRole.admin && Moment(slot.start).diff(Moment().add(lookAheadDays, 'days')) < 0 ? <Button plain onClick={() => {
    setSlotModalOpen(false);
    setNewEventModalOpen(true);
    setPreselectedScheduleData({
      hostId: hostId,
      slot: slot
    })

  }}>{label}</Button> : null;

  const createEventActivator = (
    <Button onClick={togglePopoverActive} disclosure>
      New
    </Button>
  );

  return (
    <AppPage
      title='Store calendar'
      fullWidth={true}
      secondaryActions={adminRole === UserRole.admin
        && [
          { content: 'Host Settings', icon: ProfileMinor, onAction: () => history.push('/calendar/hosts') },
          { content: 'Event types', icon: CalendarMinor, onAction: () => history.push('/calendar/eventTypes') },
          { content: 'Business Hours', icon: ClockMinor, onAction: () => history.push('/businessConfig') },
          { content: 'Advanced', icon: AdjustMinor, onAction: () => history.push('/schedulingAdvanced') },
        ]}
    >
      <Layout>
        <Layout.Section>
          <AppWarnings />
        </Layout.Section>
        <Layout.Section>
          <Card sectioned>
            <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
              <Calendar
                resources={resourceView ? hostResourceMap : undefined}
                min={minTime}
                max={maxTime}
                events={eventsForCalendar}
                onNavigate={onNavigate}
                date={date}
                backgroundEvents={backgroundEvents}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                showMultiDayTimes={true}
                defaultView={currentViewType}
                localizer={localizer}
                endAccessor={({ end }) => new Date(end.getTime() - 1)}
                views={[Views.MONTH, Views.WEEK, Views.DAY]}
                scrollToTime={scrollToTime}
                onRangeChange={onRangeChange}
                eventPropGetter={eventPropGetter}
                slotPropGetter={slotPropGetter}
                allDayAccessor={() => false}
                draggableAccessor={() => resourceView}
                components={{
                  event: Event,
                  toolbar: (e) => (
                    <div className='custom-toolbar-container'>
                      <Stack distribution='fill'>
                        <Stack vertical>
                          <Stack alignment='baseline'>
                            <Stack alignment='baseline'>
                              <Heading>{e.label} </Heading>
                              <Button plain
                                pressed={datePickerModalOpen}
                                onClick={() => setDatePickerModalOpen(true)}
                              >
                                Select date
                              </Button>
                            </Stack>
                            <Stack.Item fill>
                              <Stack distribution='trailing' >
                                <Stack.Item fill>
                                  <AppointmentSearcher goToEvent={(item) => { e.onNavigate('DATE', moment(item.startTimestamp.toDate()).startOf('day').toISOString()); setSelectedEvent(item); setEventModalOpen(true) }} />
                                </Stack.Item>
                                <Stack.Item>
                                  <Popover
                                    active={createEventsListActive}
                                    activator={createEventActivator}
                                    autofocusTarget="first-node"
                                    onClose={() => { }}
                                  >
                                    <ActionList
                                      actionRole="menuitem"
                                      items={[
                                        {
                                          content: 'Invite a client',
                                          onAction: () => { setNewEventModalOpen(true); togglePopoverActive(); }
                                        }, {
                                          content: 'Block off host',
                                          onAction: () => { setNewBlockOffModalOpen(true); togglePopoverActive(); }
                                        }
                                      ]}
                                    />
                                  </Popover>
                                </Stack.Item>
                              </Stack>
                            </Stack.Item>
                          </Stack>
                          <Stack distribution='equalSpacing'>
                            <ButtonGroup segmented>
                              {e.views.map((item, index) => (
                                <Button
                                  key={index}
                                  pressed={e.view === item}
                                  onClick={() => e.onView(item)}>
                                  {item}
                                </Button>
                              ))}
                            </ButtonGroup>
                            <Stack alignment='center'>
                              <SelectHost
                                onHostChange={setHost}
                                hosts={hosts}
                                selecetedHost={host}
                              />
                              {(businessHoursLoading || shopEventsLoading)
                                ? <Spinner size='small' />
                                : isAllHosts ? null : editHostAction(host, 'settings')
                              }
                            </Stack>
                            <ButtonGroup segmented>
                              <Button onClick={() => e.onNavigate('PREV')}>Back</Button>
                              <Button onClick={() => e.onNavigate('TODAY')}>Today</Button>
                              <Button onClick={() => e.onNavigate('NEXT')}>Next</Button>
                            </ButtonGroup>
                          </Stack>
                          <div className='host-calendar-controls'>
                            <Stack distribution='equalSpacing'>
                            </Stack>
                          </div>
                          <CalendarDatePicker
                            open={datePickerModalOpen}
                            onClose={() => setDatePickerModalOpen(false)}
                            date={date}
                            setDateTo={(newDate) => e.onNavigate('DATE', newDate)}
                          />
                        </Stack>
                        {e.children}
                      </Stack>
                    </div>
                  )
                }}
                onSelectEvent={onSelectEvent}
                onView={onView}
              />
            </div>
            <div className='legend-container'>
              <Stack>
                {LEGEND_ITEMS(currentViewType).map((item, index) => (
                  <div key={index} style={{ display: item.isHidden ? 'none' : 'block' }}>
                    <Stack wrap={false}>
                      <div className={`legend-icon ${item.type}`}>
                      </div>
                      <p> — {item.label}</p>
                    </Stack>
                  </div>
                ))}
              </Stack>
            </div>
          </Card>
        </Layout.Section>
      </Layout>
      <CaazamBlockModal
        open={blockModalOpen}
        onClose={() => { setBlockModalOpen(false); setSelectedBlock(null); }}
        data={selectedBlock}
        viewType={currentViewType}
        adminShops={adminShops}
      />
      <CaazamBlockOffModal
        open={newBlockOffModalOpen}
        onClose={() => { setNewBlockOffModalOpen(false); setSelectedBlock(null); }}
        hosts={hosts}
        data={selectedBlock}
        isHostFreeForEvent={isHostFreeForBlockOff} />
        <CreateNewAppointmentProvider
          open={newEventModalOpen}
          preselectedData={preselectedScheduleData}
          onClose={() => {
            setNewEventModalOpen(false);
            setPreselectedScheduleData(null);
           }}
          isHostFreeForEvent={isHostFreeForEvent}
          availableSlots={backgroundEvents}>
          <CaazamEventEditorModal />
        </CreateNewAppointmentProvider>
      <CaazamEventModal
        open={eventModalOpen}
        onClose={() => setEventModalOpen(false)}
        data={selectedEvent}
        isHostFreeForEvent={isHostFreeForEvent}
        rescheduleAppointment={rescheduleAppointment}
        />
      <CaazamSlotModal
        open={slotModalOpen}
        onClose={() => setSlotModalOpen(false)}
        slot={selectedslot} getHostConfig={getHostConfig}
        editHostAction={editHostAction}
        schedule={schedule} />
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <ShopCalendar />
    </AdminPage>
  )
}
