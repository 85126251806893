
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AppPage from '../../../../components/AppPage';
import AdminPage from '../../../../components/AdminPage';
import { Layout, Card, Text, Spinner, Stack, SkeletonBodyText, Avatar } from "@shopify/polaris";
import { useHistory } from 'react-router-dom';
import { useShopProvider } from '../../../../components/ShopProvider';
import { useAISessionData } from '../../../../hooks/useAIAgentSessions';
import { formatSessionTimestamp, formatSessionOrder } from '../../../../utils/agentSessions';
import { pluralize } from '../../../../utils/formatters';
import BoutiqAvatar from '../../../../components/Avatar';

import MessagesView from '../MessagesView';

const SessionDetails = () => {
  const history = useHistory();
  const { sessionId } = useParams();
  const { hosts } = useShopProvider();
  const { sessionData, sessionDataLoading, sessionDataError } = useAISessionData(sessionId);
  const customerName = sessionData?.customerName ?? 'Anonymous Customer';

  const host = useMemo(() => hosts.find(h => h.id === sessionData?.host?.id), [hosts, sessionData]);

  return (
    <AppPage
      title={<>Boutiq <sup>AI</sup> Smart Agent session</>}
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
    >
      <Layout>
        <Layout.Section>
          {sessionDataLoading && <Card><SkeletonBodyText /></Card>}
          {!sessionDataLoading &&
            <Card title="Session details" sectioned>
              <Card.Section>
                <Stack distribution='fillEvenly'>
                  <Stack vertical>
                    <Text fontWeight='semibold'>{formatSessionTimestamp(sessionData.createdAt)}</Text>
                    <Stack vertical spacing='extraTight'>
                      <Text>{sessionData.messagesCount} {pluralize(sessionData.messagesCount, 'Message')}</Text>
                      {sessionData.productsOfferedCount != null &&
                        <Text>{sessionData.productsOfferedCount} {pluralize(sessionData.productsOfferedCount, 'Product')}</Text>
                      }
                    </Stack>
                  </Stack>
                  {host && <Stack alignment="center">
                    <BoutiqAvatar
                      source={host?.avatarUrl ?? null}
                      name={host?.firstName}
                      initials={`${host?.firstName?.[0]}${host?.lastName?.[0]}`}
                    />
                    <Stack vertical>
                      <Text fontWeight="semibold">{host?.firstName} {host?.lastName}</Text>
                    </Stack>
                  </Stack>}
                </Stack>
              </Card.Section>
              <Card.Section>
                <Stack vertical>
                  <Stack vertical spacing='extraTight'>
                    <Text fontWeight='semibold'>{customerName}</Text>
                    {sessionData.customerEmail && <Text>{sessionData.customerEmail}</Text>}
                  </Stack>
                  {sessionData.totalOrderAmount && <Text fontWeight='semibold'>Order total: {formatSessionOrder(sessionData)}</Text>}
                </Stack>
              </Card.Section>
            </Card>
          }
          {sessionData && <MessagesView sessionId={sessionId} sessionData={sessionData} customerName={customerName} host={host} />}
        </Layout.Section>
      </Layout>
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <SessionDetails />
    </AdminPage>
  )
}
