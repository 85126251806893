import React, { useEffect, useMemo, useState } from 'react';
import {
    Card,
    Stack,
    Text,
    SkeletonBodyText,
    Banner,
    Avatar,
    Box,
} from '@shopify/polaris';
import BoutiqAvatar from '../../../../components/Avatar';
import useAIAgentSessionMessages from '../../../../hooks/useAIAgentSessionMessages';
import { currencyFormatter } from '../../../../utils/formatters';
import { AgentMessageRole } from '../../../../utils/agentSessions';
import './styles.css';


function getInitials(displayName) {
    if (!displayName) return 'C';
    return displayName.split(' ').map(w => w?.[0] ?? '').slice(0, 2).join('').toUpperCase();
}


function timeFormatter(time) {
    const timeInSec = parseInt(time);
    var hours = Math.floor(timeInSec / 3600);
    var minutes = Math.floor((timeInSec - (hours * 3600)) / 60);
    var seconds = timeInSec - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
}

export default function MessagesView({ sessionId, sessionData, customerName, host }) {
    const { messages, messagesLoading, messagesError } = useAIAgentSessionMessages(sessionId);
    return (
        <Card title='Session chat transcript' sectioned>
            <Card.Section>
                <Stack vertical>
                    {messagesLoading && <SkeletonBodyText />}
                    {!messagesLoading &&
                        <Stack vertical>
                            {messages.map(m => <AgentChatMessage key={m.id} message={m.data()} sessionData={sessionData} customerName={customerName} host={host} />)}
                        </Stack>
                    }
                </Stack>
            </Card.Section>
        </Card>
    )
}

function AgentChatMessage({ message, sessionData, customerName, host }) {    
    const avatarUrl = host?.avatarUrl;
    const customerInitials = getInitials(customerName);
    const messageOffset = (message.timestamp.toDate() - sessionData.createdAt.toDate()) / 1000;
    const isAgent = message.role !== AgentMessageRole.user;
    return (
        <Box
            style={{
                display: 'grid',
                gridTemplateColumns: isAgent ? '70% 50px' : '50px 70%',
                justifyContent: isAgent ? 'end' : 'start',
                justifyItems: isAgent ? 'end' : 'start',
            }}
        >
            {!isAgent && (
                <Box>
                    <Avatar name={customerName} initials={customerInitials} />
                </Box>
            )}
            <Stack distribution={isAgent && 'trailing'} alignment='leading'>
                <Stack vertical spacing='extraTight'>
                    <Text alignment={isAgent && 'end'} color='subdued' variant='bodySm'>{timeFormatter(messageOffset)}</Text>
                    <Text alignment={isAgent && 'end'}>{message.content}</Text>
                    {message.metadata?.recommended_products && <MessageProductsList metadata={message.metadata} />}
                </Stack>
            </Stack>
            {isAgent && (
                <Box>
                    {isAgent
                        ?  <BoutiqAvatar
                                source={avatarUrl ?? null}
                                name={sessionData.config.botName}
                                initials={getInitials(sessionData.config.botName)}
                                size="medium"
                            />
                        : <Avatar name={customerName} initials={customerInitials} size='extraSmall' />
                    }
                </Box>
            )}
        </Box>
    )
}

function MessageProductsList({ metadata }) {
    if (!metadata.recommended_products) return null;

    return (
        <Banner title="Products recommended" status='success'>
                <Stack distribution='trailing'>
                    {metadata.recommended_products.map(p => {
                        const { product_id, product_title, variant_id, variant_title } = p;
                        const image = metadata.product_metadata[product_id]?.variants.nodes.find(v => v.id === variant_id)?.image?.thumbnail ?? null;
                        return (<div className='boutiq-product-card'
                            key={product_id}
                        >
                            <img
                                src={image}
                                alt={product_title + ' / ' + variant_title}
                            />
                            <Text variant='bodySm'>{product_title + ' / ' + variant_title}</Text>

                        </div>)
                    })}
                </Stack>
        </Banner>
    )
}
